import React from 'react'
import {Table} from "reactstrap";

export default class WorkOrderList extends React.Component {

    render() {

        return(

                <Table hover responsive size="sm" striped>
                    <tbody>
                    <tr>
                        <th>Job ID</th>
                        <th>Client</th>
                        <th>Scheduled At</th>
                        <th>Location</th>
                    </tr>

                    {this.props.workorders
                        .sort((a,b) => {
                            var x = a.ID;
                            var y = b.ID;
                            if (x < y) {return -1;}
                            if (x > y) {return 1;}
                            return 0;
                        })
                        .map(row => {

                            const client = this.props.clients.filter(c => c.ID === row.ClientID)[0];
                            var clientName = "";
                            if (client){
                                clientName = client.Name;
                            }

                            return <tr key={row.ID}>
                                <td>{row.ID}</td>
                                <td>{clientName}</td>
                                <td>{row.ScheduledAt}</td>
                                <td>{row.Location}</td>
                            </tr>
                        })}
                    </tbody>
                </Table>
        )

    }

}
