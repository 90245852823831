import React from 'react';
import {Nav} from 'reactstrap';
import NavItem from "reactstrap/es/NavItem";
import {Link} from "react-router-dom";

export default class PhotographerSideNav extends React.Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    render() {
        return (

        <Nav vertical>

            <NavItem>
                <Link to="/crm/companies">Companies</Link>
            </NavItem>

            <NavItem>
                <Link to="/crm/clients">Clients</Link>
            </NavItem>

            <NavItem>
                <Link to="/workorders">Jobs</Link>
            </NavItem>

        </Nav>

        );
    }

}
