import React from 'react';
import {Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from 'classnames';
import Gallery from "./Gallery";

export default class GalleryOfGalleries extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: this.props.photopackDescriptor.photopack.galleries[0].path
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {

        return (

            <div id="galleryOfGalleries">
                <h3 style={{marginTop: '20px' }}>Galleries</h3>

                <Nav tabs>
                    {
                        this.props.photopackDescriptor.photopack.galleries.map(gallery => {
                            return <NavItem key={gallery.path}>
                                    <NavLink
                                        key={gallery.path}
                                        className={classnames({ active: this.state.activeTab === gallery.path })}
                                        onClick={() => { this.toggle(gallery.path); }}
                                    >
                                        {gallery.name}
                                    </NavLink>
                                </NavItem>
                        })
                    }
                </Nav>

                {
                    this.props.photopackDescriptor.photopack.galleries.map(gallery => {

                        const galleryBaseUrl = this.props.photopackUrl + '/' + gallery.path;

                        return <TabContent activeTab={this.state.activeTab} key={gallery.path}>
                            <TabPane tabId={gallery.path}>
                                <Row>
                                    <Col>
                                        <Gallery galleryBaseUrl={galleryBaseUrl} galleryInfo={gallery}></Gallery>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    })
                }



            </div>
        )

    }

}
