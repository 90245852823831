import React from 'react';
import adminAPI from "../../admin_api";
import {bindActionCreators} from "redux";
import {updateClientCompanyList} from "../../actions/crm/client_companies";
import {connect} from "react-redux";
import {updateClientList} from "../../actions/crm/clients";
import {
    updateFutureWorkOrderList,
    updatePastWorkOrderList,
    updateTodaysWorkOrderList,
} from "../../actions/workorders/workorders";
import WorkOrderList from "./WorkOrderList";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";

class WorkOrderDashboard extends React.Component {

    componentDidMount() {

        if (this.props.clients.length === 0) {
            adminAPI.get('/crm/clients')
                .then((result) => {
                    this.props.updateClientList(result.data);
                })
                .catch(
                    (e) => {
                        console.log("Error getting clients");
                        console.log(e)
                    });
        }

        if (this.props.clientCompanies.length === 0) {
            adminAPI.get('/crm/companies')
                .then((result) => {
                    this.props.updateClientCompanyList(result.data);
                })
                .catch(
                    (e) => {
                        console.log("Error getting companies");
                        console.log(e)
                    });
        }


        if (this.props.workorders.past.length === 0) {
            adminAPI.get('/workorders/jobs?daterange=past&limit=50')
                .then((result) => {
                    this.props.updatePastWorkOrderList(result.data);
                })
                .catch(
                    (e) => {
                        console.log("Error getting past jobs");
                        console.log(e)
                    });
        }


        if (this.props.workorders.today.length === 0) {
            adminAPI.get('/workorders/jobs?daterange=today')
                .then((result) => {
                    this.props.updateTodaysWorkOrderList(result.data);
                })
                .catch(
                    (e) => {
                        console.log("Error getting today's jobs");
                        console.log(e)
                    });
        }


        if (this.props.workorders.future.length === 0) {
            adminAPI.get('/workorders/jobs?daterange=future')
                .then((result) => {
                    this.props.updateFutureWorkOrderList(result.data);
                })
                .catch(
                    (e) => {
                        console.log("Error getting future jobs");
                        console.log(e)
                    });
        }

    }

    render() {

        return (
            <div>

                <Row style={{marginTop: "30px"}}>
                    <Col>
                        <Card>
                            <CardHeader><h3>Today's Workorders</h3></CardHeader>
                            <CardBody>
                                <WorkOrderList clients={this.props.clients} workorders={this.props.workorders.today} />
                            </CardBody>
                        </Card>
                    </Col>

                    <Col>

                        <Card>
                            <CardHeader><h3>Future Workorders</h3></CardHeader>
                            <CardBody>
                                <WorkOrderList clients={this.props.clients} workorders={this.props.workorders.future} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row style={{marginTop: "30px"}}>
                    <Col>
                        <Card>
                            <CardHeader><h3>Past Workorders</h3></CardHeader>
                            <CardBody>
                                <WorkOrderList clients={this.props.clients} workorders={this.props.workorders.past} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </div>

        );
    }

}




const mapStateToProps = (state) => {
    return {
        session: state.session,
        clients: state.clients,
        clientCompanies: state.clientCompanies,
        workorders: state.workorders
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateClientList,
        updateClientCompanyList,
        updatePastWorkOrderList,
        updateTodaysWorkOrderList,
        updateFutureWorkOrderList
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderDashboard);
