import React from 'react'
import {Redirect} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {login} from "../../actions/login";

class OAuth2CallbackHandler extends React.Component {

    componentDidMount() {

        const auth={
            accessToken: this.getAccessToken(),
            idToken: this.getIdToken(),
            expiresIn: this.getParameterByName("expires_in")
        };

        console.log("Calling login with auth: ");
        console.log(auth);

        this.props.login(auth);
    }

    getParameterByName(name) {
        var match = RegExp('[#&]' + name + '=([^&]*)').exec(this.props.location.hash);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }

    getAccessToken() {
        return this.getParameterByName('access_token');
    }

    getIdToken() {
        return this.getParameterByName('id_token');
    }

    render() {

        console.log("Callback render");
        console.log(this.props)

        var redirect;
        if( this.props.session.authentication.loggedIn === true) {
            redirect = <Redirect to="/"/>;
        }

        return (
            <div>
            {redirect}
            </div>

        )
    }

}


const mapStateToProps = (state) => {
    return {
        session: state.session
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({login}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(OAuth2CallbackHandler);

