import React from 'react';
import {Link} from "react-router-dom";
import {Card, CardHeader, Col, Row} from "reactstrap";
import CardBody from "reactstrap/es/CardBody";

const headerStyle={fontWeight: "bold", fontSize: "18px", textAlign: "center"};
const cardStyle={width: "100%"};
const cardBodyStyle={ textAlign: "center", fontWeight: "bold" };

export default class Login extends React.Component {

    render() {
        return(
            <Row style={{marginTop: "30px"}}>
                <Col sm={{size: "3", offset: "2"}}>
                    <Card style={cardStyle}>
                        <CardHeader style={headerStyle}>Clients</CardHeader>
                        <CardBody style={cardBodyStyle}>
                            <Link to="/pickup">Pick up your photos</Link>
                        </CardBody>
                    </Card>
                </Col>

                <Col sm={{size: "3", offset: "2"}}>
                    <Card style={cardStyle}>
                        <CardHeader style={headerStyle}>Photographers</CardHeader>

                        <CardBody style={cardBodyStyle}>
                            <a href={"https://photo-admin.auth.us-east-2.amazoncognito.com/oauth2/authorize?client_id=8ul495gs2g95fdb6a0jj1vah8&redirect_uri="
                                + window.origin
                                + "/oauth2/callback&scope=openid+profile+email&response_type=token"} >
                                Log in as a photographer
                            </a>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }

}
