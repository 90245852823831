import React from 'react';
import {Col, Row} from "reactstrap";

export default class Gallery extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {

        return (
            <Row style={{marginTop: '25px', marginBottom: '25px'}}> {
                this.props.galleryInfo.pics.map(photo => {
                    return <Col key={photo}>
                        <div style={{
                            borderColor: '#ddd', borderStyle: 'solid', borderWidth: '1px',
                            marginBottom: '20px', marginLeft: 'auto', marginRight: 'auto',
                            textAlign: 'center', padding: '4px 4px 4px 4px'
                        }}>
                            <img style={{ width: '400px', height: 'auto' }}
                                 src={this.props.galleryBaseUrl + '/' + photo}
                                 key={photo}
                                 alt="product"/>
                            <div><b>File:</b> {photo}</div>
                        </div>
                    </Col>
                })
            } </Row>
        )
    }

}
