import React from 'react';
import {Card, CardBody, CardLink} from "reactstrap";

export default class PhotopackInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <Card style={{marginTop: '25px', marginBottom: '25px'}}>

                <CardBody>

                    <div className="row">

                        <div className="col">
                            <h4>Shoot Information</h4>
                            <b>Location:</b> {this.props.photopackDescriptor.photopack.address.line1}
                        </div>

                        <div className="col">
                            <h4>Downloads</h4>
                            {
                                this.props.photopackDescriptor.photopack.downloads.map(dl => {

                                    const dlLink =  this.props.photopackUrl + '/' + dl.path + '/' + dl.filename;
                                    return <CardLink key={dl.filename} href={dlLink}>{dl.label}</CardLink>
                                })
                            }
                        </div>
                    </div>
                </CardBody>

            </Card>

        )
    }

}
