import { combineReducers } from 'redux'
import session from "./session";
import clientCompanies from "./crm/client_companies";
import clients from "./crm/clients";
import workorders from "./workorders/workorders";

const rootReducer = combineReducers({
    session,
    clientCompanies,
    clients,
    workorders
});

export default rootReducer;
