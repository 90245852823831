import React from 'react';
import Login from "./security/Login";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

class Home extends React.Component {

    render() {
        return (

            <div className="row">
                <div className="offset-2 col-8">
                    <h2>Customer Service</h2>

                    <p>
                        Welcome to the new Spadea Photography customer service site. Please pardon our appearance as
                        we build out better technology to serve you better.
                    </p>

                    <Login/>
                </div>

            </div>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        session: state.session
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
