import React from 'react';
import { Nav, Navbar, NavbarToggler} from 'reactstrap';
import NavItem from "reactstrap/es/NavItem";
import LoginLogout from "../LoginLogout";

import logo from '../../logo-192.png';
import {bindActionCreators} from "redux";
import {login, logout} from "../../actions/login";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

class TopNav extends React.Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {

        const logoStyle = {
            width: '40px',
            height: 'auto',
            marginRight: '5px',
            borderRadius: '5px'
        };

        return (

        <Navbar style={{backgroundColor: 'cadetblue'}} expand="md">
                <Link style={{color: "#fff"}} to="/">
                    <img src={logo} style={logoStyle } alt="logo" />
                    <div  style={{fontWeight: "bold", fontSize: "20pt", verticalAlign: "middle"}} className="d-inline-block">Spadea Photography</div>
                </Link>

            <NavbarToggler onClick={this.toggle} />

            <Nav className="ml-auto">
                <NavItem>
                    <LoginLogout session={this.props.session}/>
                </NavItem>
            </Nav>

        </Navbar>

        );
    }

}


const mapStateToProps = (state) => {
    return {
        session: state.session
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({login, logout}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);

