import React from 'react';
import {Card, CardTitle} from "reactstrap";

class AdminHome extends React.Component {

    render() {

        return (

            <Card>
                <CardTitle>Upcoming Jobs</CardTitle>
            </Card>


        )


    }
}

export default AdminHome;
