import React from 'react';
import PhotopackInfo from "./PhotopackInfo";
import GalleryOfGalleries from "./GalleryOfGalleries";

export default class PhotopackDetailView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        if (this.state.photopackId !== undefined && this.state.email !== undefined) {
            this.loadDescriptor();
        }
    }

    render() {

        let dlListComponent = "";
        let galleries = "";
        if ( this.props.photopackDescriptor !== undefined ) {
            dlListComponent = <PhotopackInfo photopackUrl={this.props.photopackUrl}
                                             photopackDescriptor={this.props.photopackDescriptor}/>


            galleries = <GalleryOfGalleries photopackUrl={this.props.photopackUrl}
                                            photopackDescriptor={this.props.photopackDescriptor} />
        }

        return(

            <div>

                <div className="row">
                    <div className="col">
                        {dlListComponent}
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        {galleries}
                    </div>
                </div>

            </div>
        )
    }
}
