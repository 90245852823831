import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import adminAPI from "../../../admin_api";
import {Card, CardBody, CardHeader, Col, Row, Table} from "reactstrap";
import {updateClientList} from "../../../actions/crm/clients";
import {updateClientCompanyList} from "../../../actions/crm/client_companies";

class ClientDashboard extends React.Component {

    componentDidMount() {

        if (this.props.clients.length === 0) {
            adminAPI.get('/crm/clients')
                .then((result) => {
                    this.props.updateClientList(result.data);
                })
                .catch(
                    (e) => {
                        console.log("Error getting companies");
                        console.log(e)
                    });
        }

        if (this.props.clientCompanies.length === 0) {
            adminAPI.get('/crm/companies')
                .then((result) => {
                    this.props.updateClientCompanyList(result.data);
                })
                .catch(
                    (e) => {
                        console.log("Error getting companies");
                        console.log(e)
                    });
        }
    }

    render() {

        return (

            <Row style={{marginTop: '30px'}}>
                <Col>
                    <Card>
                        <CardHeader><h3>Clients</h3></CardHeader>
                        <CardBody>
                            <Table hover responsive size="sm" striped>
                                <tbody>
                                <tr>
                                    <th>Client</th>
                                    <th>Company</th>
                                </tr>

                                {this.props.clients
                                    .sort((a,b) => {
                                        var x = a.Name.toLowerCase();
                                        var y = b.Name.toLowerCase();
                                        if (x < y) {return -1;}
                                        if (x > y) {return 1;}
                                        return 0;
                                    })
                                    .map(row => {

                                        const client = this.props.clientCompanies.filter(c => c.ID === row.CompanyID)[0];
                                        var companyName = "";
                                        if (client){
                                            companyName = client.Name;
                                        }

                                        return <tr key={row.ID}>
                                            <td>{row.Name}</td>
                                            <td>{companyName}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        );
    }

}

const mapStateToProps = (state) => {
    return {
        session: state.session,
        clients: state.clients,
        clientCompanies: state.clientCompanies
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({updateClientList, updateClientCompanyList}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientDashboard);
