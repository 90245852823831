import React from 'react';
import {Link} from "react-router-dom";

export default class LoginLogout extends React.Component {

    render() {
        return (this.props.session.authentication.loggedIn ?
                <div style={{color:"#fff"}}>Logged in</div>
                : <Link style={{color: "#fff"}} to="/login">Login</Link>
        );
    }

}
