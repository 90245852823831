const defaultAuthentication = {
    loggedIn: false,
    accessToken: null,
    idToken: null,
    expiresIn: 0
};

const defaultState = {
    authentication: {
        ...defaultAuthentication
    }
}


const session = (state = defaultState, action) => {

    switch (action.type) {
        case 'LOG_IN':

            const newState = Object.assign({}, state);
            newState["authentication"] = {
                accessToken: action.payload.accessToken,
                idToken: action.payload.idToken,
                expiresIn: action.payload.expiresIn,
                loggedIn: true
            };

            sessionStorage["tokens"] = JSON.stringify(newState["authentication"]);
            return newState;

        case 'RELOAD_LOGIN':

            const reloadedState = Object.assign({}, state);
            reloadedState["authentication"] = JSON.parse(sessionStorage["tokens"]);

            console.log("Reloaded session tokens:");
            console.log(reloadedState["authentication"]);

            return reloadedState;

        case 'LOG_OUT':

            return {
                ...state,
                authentication: {
                    ...defaultAuthentication
                }
            };

        default:
            return state
    }
}

export default session
