import React from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import PhotopackDetailView from "./PhotopackDetailView";

export default class PhotopackPickup extends React.Component {

    constructor(props) {
        super(props);

        this.loadGallery = this.loadGallery.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            photopackId: "",
            email: "",
            photopackDescriptor: undefined,
            photopackUrl: ""
        };
    }

    componentDidMount() {

        if (this.props.match.params.email) {
            let { email, pickupCode } = this.props.match.params;

            email = decodeURIComponent(email)
            pickupCode = decodeURIComponent(pickupCode)

            this.setState({
                email: email,
                photopackId: pickupCode
            }, ()=> { this.loadGallery() })


        }

    }

    loadGallery() {

        if (this.state.email !== "" && this.state.photopackId !== "") {

            const packBaseUrl = 'https://pickup.spadea.pics/photos/' + this.state.email + '/' + this.state.photopackId;

            this.setState({
                photopackUrl: packBaseUrl
            });

            fetch(packBaseUrl  + '/property.json')
                .then(res => res.json())
                .then((data) => {
                    this.setState({ photopackDescriptor: data })
                })
                .catch(
                    (e) => {
                        console.log("Error getting photopack descriptor")
                        console.log(e)
                    })

        }

    }

    handleChange({ target }) {
        this.setState({
            [target.name]: target.value
        });

        return true;
    }

    render() {

        return(

            <div>
                <Form inline style={{ marginTop: '10px'}}>

                    <FormGroup style={{ marginRight: '25px' }} controlid="formEmail">
                        <Label style={{ marginRight: '5px'}}>Email Address</Label>
                        <Input bsSize="sm" type="email" placeholder="Enter email"
                               name="email"
                               value={ this.state.email }
                               onChange={ this.handleChange }
                        />
                    </FormGroup>

                    <FormGroup style={{ marginRight: '25px' }} controlid="formPickupCode">
                        <Label style={{ marginRight: '5px'}}>Pickup Code</Label>
                        <Input bsSize="sm" type="text" placeholder="Photopack Pickup Code"
                               name="photopackId"
                               value={ this.state.photopackId }
                               onChange={ this.handleChange } />
                    </FormGroup>

                    <Button color="primary" onClick={this.loadGallery}>
                        Retrieve
                    </Button>

                </Form>

                <PhotopackDetailView photopackUrl={this.state.photopackUrl}
                                     photopackDescriptor={this.state.photopackDescriptor} />

            </div>

        );

    }

}
