const defaultState = {
    past: [],
    today: [],
    future: []
}

const workorders = (state = defaultState, action) => {

    switch (action.type) {
        case 'UPDATE_PAST_WORKORDER_LIST':
            return {
                ...state,
                past: action.payload
            };

        case 'UPDATE_TODAYS_WORKORDER_LIST':
            return {
                ...state,
                today: action.payload
            };

        case 'UPDATE_FUTURE_WORKORDER_LIST':
            return {
                ...state,
                future: action.payload
            };

        default:
            return state
    }
};

export default workorders;
