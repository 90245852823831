import React from 'react';
import './App.css';
import AppRouter from "./layout/AppRouter";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {login, logout, reloadLogin} from "./actions/login";

class App extends React.Component {
    constructor(props) {
        super(props);

        if (sessionStorage["tokens"]) {
            console.log("We have session state. Reloading...");
            this.props.reloadLogin();
        } else {
            console.log("No session state found.");
        }
    }


    render() {
        return (

            <div className="App">
                <AppRouter store={this.props.store} login={this.props.login} logout={this.props.logout}/>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        session: state.session
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({login, logout, reloadLogin}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
