import * as axios from "axios";

const adminAPI = axios.create({
    baseURL: "https://api.spadea.pics",
    timeout: 10000,
    //withCredentials: true
});

adminAPI.interceptors.request.use(function (config) {

    const auth = JSON.parse(sessionStorage["tokens"]);
    config.headers.Authorization = "Bearer " + auth["accessToken"];
    config.headers['Content-Type'] = "application/json";
    config.headers.Accept = "application/json";
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export default adminAPI;
