import React from 'react';

export default class BillingDashboard extends React.Component {

    render() {
        return(
            <h1>Billing</h1>
        )
    }


}
