
const clientCompanies = (state = [], action) => {

    switch (action.type) {
        case 'UPDATE_CLIENT_COMPANY_LIST':

            return action.payload

        default:
            console.log("Hit default!");
            return state
    }
};

export default clientCompanies
