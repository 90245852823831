import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import TopNav from "./topnav/TopNav";
import Home from "../content/Home";
import CompanyDashboard from "../content/crm/clients/CompanyDashboard";
import ClientDashboard from "../content/crm/clients/ClientDashboard"
import PhotographerSideNav from "./PhotographerSideNav";
import Login from "../content/security/Login";
import PhotopackPickup from "../content/photopacks/PhotopackPickup";
import Callback from "../security/oauth/Callback";
import WorkOrderDashboard from "../content/workorders/WorkOrderDashboard";
import BillingDashboard from "../content/billing/BillingDashboard";
import AdminHome from "../content/AdminHome";

export default class AppRouter extends React.Component {

    render() {
        var content;

        const state = this.props.store.getState();
        const session = state.session;

        if (session && session.authentication.loggedIn) {
            content =
                <div className="row">
                    <div className="col-1">
                        <PhotographerSideNav />
                    </div>

                    <div className="col">
                        <Route path="/" exact component={AdminHome}/>
                        {/* OAuth2 callback has to be in the authenticated routes too, because it
                           needs to handle the post-login redirect AND silent token refreshes while
                           logged in.
                         */}
                        <Route path="/oauth2/callback" exact component={Callback}/>
                        <Route path="/login" exact component={Login}/>
                        <Route path="/crm/companies" exact component={CompanyDashboard} />
                        <Route path="/crm/clients" exact component={ClientDashboard} />
                        <Route path="/workorders" exact component={WorkOrderDashboard} />
                        <Route path="/billing" exact component={BillingDashboard} />
                        <Route path="/pickup(/:email)(/:pickupCode)" exact component={PhotopackPickup}/>
                    </div>
                </div>

        } else {

            content =
            <div className="row">
                <div className="col">
                    <Route path="/" exact component={Home}/>
                    <Route path="/oauth2/callback" exact component={Callback}/>
                    <Route path="/login" exact component={Login}/>
                    <Route path="/pickup/:email?/:pickupCode?" exact component={PhotopackPickup}/>
                </div>
            </div>
        }

        return (
            <Router>
                <TopNav store={this.props.store}/>

                <div className="container-fluid">
                    {content}
                </div>
            </Router>
        );
    }
}

