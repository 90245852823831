
export const login = auth => ({
    type: 'LOG_IN',
    payload: auth
});

export const reloadLogin = () => ({
   type: "RELOAD_LOGIN"
});

export const logout = () => ({
    type: 'LOG_OUT'
});
