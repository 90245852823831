export const updatePastWorkOrderList = (workOrders) => ({
    type: 'UPDATE_PAST_WORKORDER_LIST',
    payload: workOrders
});


export const updateTodaysWorkOrderList = (workOrders) => ({
    type: 'UPDATE_TODAYS_WORKORDER_LIST',
    payload: workOrders
});

export const updateFutureWorkOrderList = (workOrders) => ({
    type: 'UPDATE_FUTURE_WORKORDER_LIST',
    payload: workOrders
});
